import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import GoogleMapReact from "google-map-react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import maptova from "../assets/images/web-map-tovaIcon.svg";
import mapind from "../assets/images/web-mapIndication.svg";
import ContactForm from "./ContactForm";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { APP_NAME, GOOGLEANALYTICS_SCRIPT, GOOGLEANALYTICS_SOURCE, ZOOMINFO_SCRIPT } from "../config/env";
import { t } from "i18next";


const PrivacyPolicy = () => {
  const { t } = useTranslation();
   

  useEffect(() => {

    // *********************************************************************
    // GOOGLE ANALYTICS CODE 
    // *********************************************************************
    if (GOOGLEANALYTICS_SOURCE.length > 1 && GOOGLEANALYTICS_SCRIPT.length > 1) {
      const script1 = document.createElement("script");
      script1.setAttribute("src", GOOGLEANALYTICS_SOURCE);
      document.head.appendChild(script1);

      const script2 = document.createElement("script");
      script2.innerHTML = GOOGLEANALYTICS_SCRIPT;
      document.head.appendChild(script2)
      return () => {
        // clean up the script when the component in unmounted
        document.head.removeChild(script1);
        document.head.removeChild(script2);
      }
    }


    // *********************************************************************
    // ZOOMINFO CODE 
    // *********************************************************************
    if (ZOOMINFO_SCRIPT.length > 0) {
      const script = document.createElement("script");
      script.innerHTML = ZOOMINFO_SCRIPT;
      document.head.appendChild(script)
      return () => {
        // clean up the script when the component in unmounted
        document.head.removeChild(script)
      }
    }
  }, []);

  return (
    <>
      <Navbar />
      <div className="hide-overflowX bg-white">
        <Row className="frame">
          <Row className="max-w-1488px justify-content-sm-center justify-content-md-start  px-0 ml-10px">
            <Col
              xs={12}
              md={12}
              lg={7}
              className="flex-col space-evenly mt-140px   ps-0"
            >
              <h1 className="ms-md-5 ms-0 text-md-start text-center">{t("privacyPolicy.privacyPolicyHeading")}</h1>
            </Col>
          </Row>
        </Row>
        <div className="px-5 mb-5 ml-10px">       
            <br />
            <br />
            <br/>
            {/* <p className="home-feature-h">{t("privacyPolicy.policyHeading")}</p>
            <p className="home-feature-h">{t("privacyPolicy.policyHeading")}</p> */}

            <p className="home-feature-h">{t("privacyPolicy.policyHeading1")}</p>
            <p className="home-feature">{t("privacyPolicy.policyMatter1")} </p>
            <br/>
            <p className="home-feature-h">{t("privacyPolicy.policyHeading2")}</p>
            <p className="home-feature">{t("privacyPolicy.policyMatter2")} </p>
            <br/>
            <p className="home-feature-h">{t("privacyPolicy.policyHeading3")}</p>
            <p className="home-feature">{t("privacyPolicy.policyMatter3")} </p>
            <br />
             
        </div>



         
         
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
