import i18n from "../utilities/i18n";

export const env_var = {'API_URL': process.env.REACT_APP_API_URL}
export const server_url_var = process.env.REACT_APP_SITE_URL;
export const APP_NAME = i18n.t("projectName");  
export const APP_VERSION = APP_NAME+" "+process.env.REACT_APP_VERSION;

// Third party token
export const GOOGLE_RECAPTCHA_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY;
export const stripe_token_var = process.env.REACT_APP_STRIPE_TOKEN;
export const tip_server_url_var = process.env.REACT_APP_TIP_SERVER_URL;

export const notification_shown_time_success = 2000;
export const notification_shown_time_failed  = 3000;
export const notification_shown_time_failed4 = 4000;

export const DEFAULT_COUNTRY  = i18n.t("defaultCountry"); 
export const DEFAULT_CURRENCY = i18n.t("defaultCurrency"); 

export const TIP_AMT_MAX_VALUE = 999; 
export const SMS_MAX_LENGTH = 160; 
export const TIP_FACT_HEADER_MAX_LENGTH = 80;
export const TIP_FACT_BODY_MAX_LENGTH = 180;
export const TIP_CONFIRM_MSG_MAX_LENGTH=200;
export const REVIEW_HEADER_MAX_LENGTH = 100;
export const REVIEW_BODY_MAX_LENGTH   = 200;
export const REVIEW_PLATFORM_NAME_MAX_LENGTH = 50;

export const USER_ROLE_ADMIN_SUPERADMIN = 1;
export const USER_ROLE_ADMIN_ADMIN      = 2;
export const USER_ROLE_ACCOUNT_MANAGER  = 3;
export const USER_ROLE_ACCOUNT_USER     = 4;
export const arrAccountUserIds = [USER_ROLE_ACCOUNT_MANAGER, USER_ROLE_ACCOUNT_USER];

export const USER_GROUP_SUPERADMIN      = 1;
export const USER_GROUP_HOTELS          = 2; 
export const USER_GROUP_STAFF           = 3;
export const USER_GROUP_GUESTS          = 4;

export const STAFF_PAYMENT_METHOD_BANK  = 1;   // USED IN STAFF DETAILS SLIDER 
export const STAFF_PAYMENT_METHOD_CHECK = 2;   // CHECK => CHEQUE 

export const STRIPE_COMMISION = 0.3; 
export const STRIPE_COMMISION_STRING = i18n.t("env.stripeCommisionString");  

export const ACCOUNT_MIN_COMMISSION = 2.9; 

export const STAFF_DOC_TYPE_PASSOPORT_ID            = 1;
export const STAFF_DOC_TYPE_PASSOPORT               = i18n.t("env.passport");  
export const STAFF_DOC_TYPE_PASSOPORT_CARD_ID       = 2;
export const STAFF_DOC_TYPE_PASSOPORT_CARD          = i18n.t("env.passportCard"); 
export const STAFF_DOC_TYPE_DRIVER_LICENSE_ID       = 3;
export const STAFF_DOC_TYPE_DRIVER_LICENSE          = i18n.t("env.driverLicense");
export const STAFF_DOC_TYPE_STATE_ID_CARD_ID        = 4;
export const STAFF_DOC_TYPE_STATE_ID_CARD           = i18n.t("env.stateIssuedIDCard"); 
export const STAFF_DOC_TYPE_GREEN_CARD_ID           = 5;
export const STAFF_DOC_TYPE_GREEN_CARD              = i18n.t("env.residentPermitID_GreenCard");  
export const STAFF_DOC_TYPE_BORDER_CROSSING_CARD_ID = 6;
export const STAFF_DOC_TYPE_BORDER_CROSSING_CARD    = i18n.t("env.borderCrossingCard"); 
export const STAFF_DOC_TYPE_CHILD_ID_CARD_ID        = 7;
export const STAFF_DOC_TYPE_CHILD_ID_CARD           = i18n.t("env.childIDCard"); 
export const STAFF_DOC_TYPE_NYC_CARD_ID             = 8;
export const STAFF_DOC_TYPE_NYC_CARD                = i18n.t("env.NYC_Card");  
export const STAFF_DOC_TYPE_US_VISA_CARD_ID         = 9;
export const STAFF_DOC_TYPE_US_VISA_CARD            = i18n.t("env.visaCard");

export const COMPANY_DOC_TYPE_IRS_LETTER_147C_ID    = 10;
export const COMPANY_DOC_TYPE_IRS_LETTER_147C       = i18n.t("env.IRS_Letter_147C"); 
export const COMPANY_DOC_TYPE_IRS_SS4_LETTER_ID     = 11;
export const COMPANY_DOC_TYPE_IRS_SS4_LETTER        = i18n.t("env.IRS_SS-4_ConfirmationLetter"); 

export const PAY_STATUS_CODE_PENDING                = 1;   
export const PAY_STATUS_CODE_SUCCESS                = 2;  
export const PAY_STATUS_CODE_FAILED                 = 3;  
export const PAY_STATUS_CODE_READY                  = 4;  
export const PAY_STATUS_CODE_ONHOLD                 = 5;  
export const PAY_STATUS_CODE_PAYOUT_START           = 6; 
export const PAY_STATUS_CODE_REPROCESSING           = 7; 

export const PAYMENTS_FILTER_STATUS_ALL            = i18n.t("env.all"); 

export const ROUTE_SCHEDULE_DEMO            = 'https://calendly.com/brett-manger/30min'; 
export const ROUTE_SA_LOGIN                 = '/admin'; 
export const ROUTE_LOGIN                    = '/loginpage'; 
export const ROUTE_RESET_PASSWORD           = '/resetpassword'; 
export const ROUTE_FORGOT_PASSWORD          = '/forgotpassword'; 
export const ROUTE_FORGOT_PASSEMAIL_TEMPLATE= '/fpemailtemplate'; 
export const ROUTE_ACCOUNTS_LOGIN           = '/user'; 
export const ROUTE_DASHBOARD                = '/home'; 
export const ROUTE_ACCOUNTS_LIST            = '/accountslist'; 
export const ROUTE_ACCOUNT_DETAILS          = '/account'; 
export const ROUTE_ACCOUNT_WITHHOLDINGS     = '/withholdings'; 
export const ROUTE_GROUP                    = '/group'; 
export const ROUTE_STAFF_LIST               = '/staff'; 
export const ROUTE_STAFF_DETAIL             = '/staffdetail/'; 
export const ROUTE_STAFF_PROFILE            = '/staffdetail/:staffId/:accountId'; 
export const ROUTE_TIPS_LIST                = '/tips'; 
export const ROUTE_PAYMENTS_LIST            = '/payments'; 
export const ROUTE_COMMISSIONS_LIST         = '/commission'; 
export const ROUTE_SETTINGS                 = '/settings'; 
export const ROUTE_TERMS                    = '/terms'; 
export const ROUTE_SA_USERS                 = '/adminusers'; 
export const ROUTE_HOTEL_USERS              = '/accountusers'; 
export const ROUTE_ACCOUNT_GROUP            = '/accountgroup'; 


export const ROUTE_STAFF_REG_WITHOUT_EMAIL            = '/staff_registration'; 

export const ROUTE_TIP                  = '/tip'; 
export const ROUTE_TIP_SUCCESS          = '/success'; 
export const ROUTE_TIP_THANKYOU          = '/thankyou'; 
export const ROUTE_TIP_RECEIPT = '/receipt';
export const ROUTE_ACCOUNT_SIGNUP                   = '/signup'; 
export const ROUTE_ACCOUNT_SIGNUP_VERIFY_ACCOUNT    = '/signup/verify_account'; 
export const ROUTE_ACCOUNT_SIGNUP_AGREEMENT         = '/signup/agreement'; 
export const ROUTE_ACCOUNT_SIGNUP_ACH               = '/signup/ach'; 
export const ROUTE_ACCOUNT_SIGNUP_LOGO_UPLOAD       = '/signup/upload'; 

export const ROUTE_ABOUT                    = '/about'; 
export const ROUTE_SUPPORT                  = '/support'; 
export const ROUTE_404                      = "*"; 
export const ROUTE_PRIVACYPOLICY            = '/privacy-policy'; 


export const ARR_ROWS_PER_PAGE_VALUES   = [10,20,30,40,50]; 
export const PAGINATION_NUM_ROWS        = 20; // MUST BE ANY VALUE FROM 'ARR_ROWS_PER_PAGE_VALUES' ARRAY 

export const BANK_ACC_TYPE_ID_CHECKING  = 1;
export const BANK_ACC_TYPE_ID_SAVING    = 2; 

export const BUSINESS_CLASSIFICATION_LLC  = 1;

export const ARR_STAFF_DOC_TYPES = [
  {docId: STAFF_DOC_TYPE_PASSOPORT_ID, docName: STAFF_DOC_TYPE_PASSOPORT}, 
  {docId: STAFF_DOC_TYPE_PASSOPORT_CARD_ID, docName: STAFF_DOC_TYPE_PASSOPORT_CARD}, 
  {docId: STAFF_DOC_TYPE_DRIVER_LICENSE_ID, docName: STAFF_DOC_TYPE_DRIVER_LICENSE}, 
  {docId: STAFF_DOC_TYPE_STATE_ID_CARD_ID, docName: STAFF_DOC_TYPE_STATE_ID_CARD}, 
  {docId: STAFF_DOC_TYPE_GREEN_CARD_ID, docName: STAFF_DOC_TYPE_GREEN_CARD}, 
  {docId: STAFF_DOC_TYPE_BORDER_CROSSING_CARD_ID, docName: STAFF_DOC_TYPE_BORDER_CROSSING_CARD}, 
  {docId: STAFF_DOC_TYPE_CHILD_ID_CARD_ID, docName: STAFF_DOC_TYPE_CHILD_ID_CARD}, 
  {docId: STAFF_DOC_TYPE_NYC_CARD_ID, docName: STAFF_DOC_TYPE_NYC_CARD}, 
  {docId: STAFF_DOC_TYPE_US_VISA_CARD_ID, docName: STAFF_DOC_TYPE_US_VISA_CARD}
];

export const HOTEL_CODE_MAX_LENGTH = 50; 

// USED IN GROUP DETAILS 
export const GROUP_TYPE_STAFF_ID = 1; 
export const GROUP_TYPE_STAFF_NAME = i18n.t("env.staff"); 
export const GROUP_TYPE_OTHER_ID = 2; 
export const GROUP_TYPE_OTHER_NAME = i18n.t("env.other");  
// USED IN ACCOUNT DETAILS 
export const ROOM_NUMBER_VALUE = 1; // USED FOR HOTEL ROOM LABEL - IN HOTEL ADD/EDIT
export const ROOM_NUMBER_LABEL = i18n.t("env.roomNumber"); 
export const ROOM_NAME_VALUE   = 2; 
export const ROOM_NAME_LABEL   = i18n.t("env.roomName"); 
export const ROOM_NAME_REMOVE_VALUE = 3; 
export const ROOM_NAME_REMOVE_LABEL = i18n.t("env.roomNameRemove"); 

// USED IN GROUP DETAILS 
export const GROUP_PAYOUT_MODE_AUTO_ID = 1; 
export const GROUP_PAYOUT_MODE_MANUAL_ID = 2; 

// USED IN GROUP DETAILS 
export const GROUP_PAYOUT_FREQ_DIRECT_ID        = 1;  // INSTANT OR DIRECT
export const GROUP_PAYOUT_FREQ_DAILY_ID         = 2;   
export const GROUP_PAYOUT_FREQ_WEEKLY_ID        = 3;   
export const GROUP_PAYOUT_FREQ_BI_WEEKLY_ID     = 4;   
export const GROUP_PAYOUT_FREQ_MONTHLY_FIRST_ID = 5;   
export const GROUP_PAYOUT_FREQ_MONTHLY_LAST_ID  = 6;   
export const GROUP_PAYOUT_FREQ_MONTHLY_CUSTOM_ID= 7;   
export const GROUP_PAYOUT_FREQ_SEMI_MONTHLY_ID  = 8; // 1-15 & 15 to END OF MONTH 

export const ARR_PAYOUT_FREQUENCIES = [
  {id:GROUP_PAYOUT_FREQ_DIRECT_ID, name:i18n.t("payoutFrequencies.direct")},
  {id:GROUP_PAYOUT_FREQ_DAILY_ID, name:i18n.t("payoutFrequencies.daily")},
  {id:GROUP_PAYOUT_FREQ_WEEKLY_ID, name:i18n.t("payoutFrequencies.weekly")},
  {id:GROUP_PAYOUT_FREQ_BI_WEEKLY_ID, name:i18n.t("payoutFrequencies.biWeekly")},
  {id:GROUP_PAYOUT_FREQ_MONTHLY_FIRST_ID, name:i18n.t("payoutFrequencies.monthlyFirst")},
  {id:GROUP_PAYOUT_FREQ_MONTHLY_CUSTOM_ID, name:i18n.t("payoutFrequencies.monthlyCustom")},
  {id:GROUP_PAYOUT_FREQ_SEMI_MONTHLY_ID, name:i18n.t("payoutFrequencies.semiMonthly")},
]; 
// {id:GROUP_PAYOUT_FREQ_MONTHLY_LAST_ID, name:i18n.t("payoutFrequencies.monthlyLast")}, - REMOVED 31MAY2023 AS PER CLIENT SUGESTION

// USED IN GROUP DETAILS - PAYOUT DESTINATION 
export const PAYOUT_TOVA          = 1;   // USED FOR ADDING BANK ACC - IN HOTEL ADD/EDIT
export const PAYOUT_TOVA_TEXT     = i18n.t("env.payroll");   // USED FOR ADDING BANK ACC - IN HOTEL ADD/EDIT
export const PAYOUT_ACCOUNT       = 2;   // HOTEL 
export const PAYOUT_ACCOUNT_TEXT  = i18n.t("env.hotelBankAcc"); 
export const PAYOUT_STAFF         = 3;
export const PAYOUT_STAFF_TEXT    = i18n.t("env.staffBankAcc"); 

export const GROUP_STAFF_DETAILS_ROWS = 3; 

//FOR WITHHOLDING IN STAFFPROFILE (TO SET STATIC YEAR)
export const WITHHOLDING_YEAR = 2023; 

// PHONE INPUT SETTINGS  
export const PHONEINPUT_COUNTRIES = [i18n.t("phoneInputCountry")]; 
export const PHONEINPUT_COUNTRYCODE_EDITABLE = false; 

// US - STATE ARRAY  
export const US_STATES_ARRAY = [
  { "stateCode":i18n.t("env.AL"), "stateName": i18n.t("env.alabama")},
  { "stateCode":i18n.t("env.AK"), "stateName": i18n.t("env.alaska")},
  { "stateCode":i18n.t("env.AS"), "stateName":i18n.t("env.americanSamoa") },
  { "stateCode":i18n.t("env.AZ"), "stateName":i18n.t("env.arizona")  },
  { "stateCode":i18n.t("env.AR"), "stateName":i18n.t("env.arkansas") },
  { "stateCode":i18n.t("env.CA"), "stateName":i18n.t("env.california") },
  { "stateCode":i18n.t("env.CO"), "stateName":i18n.t("env.colorado") },
  { "stateCode":i18n.t("env.CT"), "stateName":i18n.t("env.connecticut")  },
  { "stateCode":i18n.t("env.DE"), "stateName":i18n.t("env.delaware") },
  { "stateCode":i18n.t("env.DC"), "stateName":i18n.t("env.districtOfColumbia") },
  { "stateCode":i18n.t("env.FM"), "stateName":i18n.t("env.federatedStatesOfMicronesia") },
  { "stateCode":i18n.t("env.FL"), "stateName":i18n.t("env.florida")  },
  { "stateCode":i18n.t("env.GA"), "stateName":i18n.t("env.georgia")  },
  { "stateCode":i18n.t("env.GU"), "stateName":i18n.t("env.guam") },
  { "stateCode":i18n.t("env.HI"), "stateName":i18n.t("env.hawaii") },
  { "stateCode":i18n.t("env.ID"), "stateName":i18n.t("env.idaho")  },
  { "stateCode":i18n.t("env.IL"), "stateName":i18n.t("env.illinois") },
  { "stateCode":i18n.t("env.IN"), "stateName":i18n.t("env.indiana")  },
  { "stateCode":i18n.t("env.IA"), "stateName":i18n.t("env.iowa") },
  { "stateCode":i18n.t("env.KS"), "stateName":i18n.t("env.kansas") },
  { "stateCode":i18n.t("env.KY"), "stateName":i18n.t("env.kentucky") },
  { "stateCode":i18n.t("env.LA"), "stateName":i18n.t("env.louisiana")  },
  { "stateCode":i18n.t("env.ME"), "stateName":i18n.t("env.maine")  },
  { "stateCode":i18n.t("env.MH"), "stateName":i18n.t("env.marshallIslands") },
  { "stateCode":i18n.t("env.MD"), "stateName":i18n.t("env.maryland") },
  { "stateCode":i18n.t("env.MA"), "stateName":i18n.t("env.massachusetts")  },
  { "stateCode":i18n.t("env.MI"), "stateName":i18n.t("env.michigan") },
  { "stateCode":i18n.t("env.MN"), "stateName":i18n.t("env.minnesota")  },
  { "stateCode":i18n.t("env.MS"), "stateName":i18n.t("env.mississippi")  },
  { "stateCode":i18n.t("env.MO"), "stateName":i18n.t("env.missouri") },
  { "stateCode":i18n.t("env.MT"), "stateName":i18n.t("env.montana")  },
  { "stateCode":i18n.t("env.NE"), "stateName":i18n.t("env.nebraska") },
  { "stateCode":i18n.t("env.NV"), "stateName":i18n.t("env.nevada") },
  { "stateCode":i18n.t("env.NH"), "stateName":i18n.t("env.newHampshire")  },
  { "stateCode":i18n.t("env.NJ"), "stateName":i18n.t("env.newJersey") },
  { "stateCode":i18n.t("env.NM"), "stateName":i18n.t("env.newMexico") },
  { "stateCode":i18n.t("env.NY"), "stateName":i18n.t("env.newYork") },
  { "stateCode":i18n.t("env.NC"), "stateName":i18n.t("env.northCarolina") },
  { "stateCode":i18n.t("env.ND"), "stateName":i18n.t("env.northDakota") },
  { "stateCode":i18n.t("env.MP"), "stateName":i18n.t("env.northernMarianaIslands") },
  { "stateCode":i18n.t("env.OH"), "stateName":i18n.t("env.ohio") },
  { "stateCode":i18n.t("env.OK"), "stateName":i18n.t("env.oklahoma") },
  { "stateCode":i18n.t("env.OR"), "stateName":i18n.t("env.oregon") },
  { "stateCode":i18n.t("env.PW"), "stateName":i18n.t("env.palau")  },
  { "stateCode":i18n.t("env.PA"), "stateName":i18n.t("env.pennsylvania") },
  { "stateCode":i18n.t("env.PR"), "stateName":i18n.t("env.puertoRico")  },
  { "stateCode":i18n.t("env.RI"), "stateName":i18n.t("env.rhodeIsland") },
  { "stateCode":i18n.t("env.SC"), "stateName":i18n.t("env.southCarolina") },
  { "stateCode":i18n.t("env.SD"), "stateName":i18n.t("env.southDakota") },
  { "stateCode":i18n.t("env.TN"), "stateName":i18n.t("env.tennessee")  },
  { "stateCode":i18n.t("env.TX"), "stateName":i18n.t("env.texas")  },
  { "stateCode":i18n.t("env.UT"), "stateName":i18n.t("env.utah") },
  { "stateCode":i18n.t("env.VT"), "stateName":i18n.t("env.vermont")  },
  { "stateCode":i18n.t("env.VI"), "stateName":i18n.t("env.virginIslands") },
  { "stateCode":i18n.t("env.VA"), "stateName":i18n.t("env.virginia") },
  { "stateCode":i18n.t("env.WA"), "stateName":i18n.t("env.washington") },
  { "stateCode":i18n.t("env.WV"), "stateName":i18n.t("env.westVirginia")  },
  { "stateCode":i18n.t("env.WI"), "stateName":i18n.t("env.wisconsin")  },
  { "stateCode":i18n.t("env.WY"), "stateName":i18n.t("env.wyoming")  }
]; 

// STAFF STATUS
export const STAFF_STATUS_ACTIVE    = 1;    
export const STAFF_STATUS_INACTIVE  = 2;    
export const STAFF_STATUS_INVITE    = 3;
export const STAFF_STATUS_DELETE    = 4; 

// Third party scripts
const REACT_APP_ATTACH_ZOOMINFO_SCRIPT: string = process.env.REACT_APP_ATTACH_ZOOMINFO_SCRIPT;
let ZOOMINFO_SCRIPT: string = '';

if (REACT_APP_ATTACH_ZOOMINFO_SCRIPT) {
  ZOOMINFO_SCRIPT =  "window[(function(_G8Y,_r8){var _EF='';for(var _ez=0;_ez<_G8Y.length;_ez++){var _Dt=_G8Y[_ez].charCodeAt();_Dt-=_r8;_Dt+=61;_EF==_EF;_r8>3;_Dt%=94;_Dt+=33;_Dt!=_ez;_EF+=String.fromCharCode(_Dt)}return _EF})(atob('fm10ODUwKyk6bys/'), 36)] = 'e98b58fa431680122622'; var zi = document.createElement('script'); (zi.type = 'text/javascript'), (zi.async = true), (zi.src = (function(_uyh,_9z){var _rR='';for(var _15=0;_15<_uyh.length;_15++){_hE!=_15;var _hE=_uyh[_15].charCodeAt();_9z>1;_hE-=_9z;_rR==_rR;_hE+=61;_hE%=94;_hE+=33;_rR+=String.fromCharCode(_hE)}return _rR})(atob('Ljo6NjleU1MwOVJAL1E5KTgvNjo5Uik1M1NAL1E6Jy1SMDk='), 36)), document.readyState === 'complete'?document.body.appendChild(zi): window.addEventListener('load', function(){ document.body.appendChild(zi) });";
}
export {ZOOMINFO_SCRIPT};
export const GOOGLEANALYTICS_SOURCE: string  = process.env.REACT_APP_GOOGLEANALYTICS_SOURCE_ARR;
export const GOOGLEANALYTICS_SCRIPT: string = process.env.REACT_APP_GOOGLEANALYTICS_SCRIPT_ARR;

export const TIP_DESIGN_1   = 1; 
export const TIP_DESIGN_2   = 2; 
export const TIP_DESIGN_3   = 3; 

export const TIP_THEME_DEFAULT_COLOR = '#1B3CA3'; 
export const TIP_THEME_DEFAULT_TEXT_COLOR = '#FFFFFF'; 


export const REVIEW_PLATFORM_DEFAULT_BG_COLOR   = '#FFFFFF'; 
export const REVIEW_PLATFORM_DEFAULT_TEXT_COLOR = '#000000'; 

// USED IN CORRESPONDING PAGES AND FilterSection PAGE
export const PAGENAME_DASHBOARD            = "pageDashBoard"; 
export const PAGENAME_ACCOUNTS             = "pageAccountsList"; 
export const PAGENAME_ACCOUNT_DETAILS      = "pageAccountDetails"; 
export const PAGENAME_TIPS                 = "pageTipsList"; 
export const PAGENAME_COMMISSIONS          = "pageCommissionsList"; 
export const PAGENAME_PAYOUTS              = "pagePayoutsList"; 
export const PAGENAME_WITHHOLDINGS         = "pageWithholdingsList"; 
export const PAGENAME_ADMINUSERS           = "pageAdminUsersList"; 
export const PAGENAME_ACCOUNTUSERS         = "PageAccountUsersList"; 
export const PAGENAME_STAFF                = "pageStaffList"; 
export const PAGENAME_TERMS                = "Terms"; 
export const PAGENAME_SETTINGS             = "Settings"; 
export const PAGENAME_PROCESS_PAYMENTS     = "ProcessPaymentsList"; 
export const PAGENAME_GROUP_DETAILS        = "groupDetails"; 
export const PAGENAME_TEMP_STAFF_ADD       = "tempStaffAdd"; 
export const PAGENAME_QRCODES              = "pageQRCodes"; 
export const PAGENAME_QRCHECKOUT           = "pageQRCodeCheckout"; 
export const PAGENAME_QRSHIPPING           = "pageQRCodeShipping"; 
export const PAGENAME_QRPAYMENT            = "pageQRCodePayment"; 
export const PAGENAME_QRC_PURCHASE_HISTORY = "pageQRCodePurchaseHistory"; 


// DATE FILTER VALUES (NOT LABELS - LABELS ARE IN en.jason)
export const DATEFILTER_ALLDATES  = "";
export const DATEFILTER_TODAY     = "today";
export const DATEFILTER_THISWEEK  = "thisWeek";
export const DATEFILTER_THISMONTH = "thisMonth";
export const DATEFILTER_THISYEAR  = "thisYear";

// AMOUNT FILTER - DEFAULT VALUES 
export const AMOUNTFILTER_DEFAULT_FROM_VALUE = 0;
export const AMOUNTFILTER_DEFAULT_TO_VALUE = 1000;

export const MIN_MOBILE_NUM_LENGTH = 8; 


