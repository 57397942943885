import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from "dayjs";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Avatar, Box, IconButton, Modal, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import PhoneInput from "react-phone-input-2";
// import 'react-phone-input-2/lib/style.css'

import back from "../../assets/images/back.svg";
import avatarImg from "../../assets/images/avatar1.svg";
import warning from "../../assets/images/warning.svg";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import { isEmail, isValidFileExtension } from "../../helpers/validators";
import { useAppDispatch, useAppSelector } from "../../helpers/hook";

import { checkActionPermission, PERM_QRCODE_ORDER, PERM_STAFF_EDIT, } from "../../config/permissions";

import { getAdminUserRole, setLastActivePageAPIData } from "../../services/localStorage";
import {
  deleteVerificationImg,
  fetchStaff,
  fetchWithholdingsDetail,
  getQrCode,
  removeLogo,
  updateStaffDetail,
  uploadProfilePic,
  uploadVerificationDocument,
} from "../../services/staffProfileService";

import { IStaffDetails } from "../../models/staffProfileInterface";
import {
  ROUTE_ACCOUNT_DETAILS,
  ROUTE_PAYMENTS_LIST,
  ROUTE_STAFF_LIST,
  ROUTE_TIPS_LIST,
  ARR_STAFF_DOC_TYPES,
  PAY_STATUS_CODE_SUCCESS,
  PAY_STATUS_CODE_READY,
  PAY_STATUS_CODE_ONHOLD,
  PAY_STATUS_CODE_PENDING,
  PAY_STATUS_CODE_FAILED,
  WITHHOLDING_YEAR,
  PHONEINPUT_COUNTRIES,
  US_STATES_ARRAY,
  PAGINATION_NUM_ROWS,
  PHONEINPUT_COUNTRYCODE_EDITABLE,
} from "../../config/env";
import { ROUTE_QRCODES } from "../../config/env_qrcodes";

import { ButtonPrimary } from "../../components/ButtonGroups/ButtonPrimary";
import { ButtonInLine } from "../../components/ButtonGroups/ButtonInLine";
import { Textfield } from "../../components/TextField";
import { SelectField } from "../../components/SelectField";
import { setNotification } from "../../services/apiService";
import { GetPayoutStatusText } from "../../config/common_functions";

import { selectAccountStaffDataSlice, setAccountStaff } from "./staffProfileSlice";
import { ButtonModal } from "../../components/ButtonGroups/ButtonModal";

import { ICountry, ICountryCode, ICountryStates, IState } from '../../models/countryStateInterfaces.ts';
import { fetchCountryStates } from "../../services/countryStateServices.tsx";

interface CustomizedState {
  calledFrom: string,
  prevAccIds: string,
  prevDateFilterType: string,
  prevFromDate: string,
  prevToDate: string,
  prevStatus: string,
  prevTipsType: string,
  prevTipsFromDate: string,
  prevTipsToDate: string,
  prevSearch: string,
  prevSort: string,
  prevPage: number,
  prevNumRowsPerPage: number;
}

function StaffDetail() {
  const pageName = "staffDetails";
  const adminUserRole = getAdminUserRole();
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const location = useLocation();
  const stateData = location.state as CustomizedState; // Type Casting, then you can get the params passed via router

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "auto",
    bgcolor: 'background.paper',
    border: '1px solid white',
    borderRadius: 1,
    boxShadow: 24,
    p: 3,
  };
  const style1 = {
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 460,
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "13px",
    boxShadow: 24,
  };

  const formatDate = (date) => {
    return dayjs(date).format('MM-DD-YYYY'); // Adjust the format as needed
  };

  const prevAccIds = stateData && stateData.prevAccIds ? stateData.prevAccIds : "";
  const prevDateFilterType = stateData && stateData.prevDateFilterType ? stateData.prevDateFilterType : "";
  const prevFromDate = stateData && stateData.prevFromDate ? stateData.prevFromDate : "";
  const prevToDate = stateData && stateData.prevToDate ? stateData.prevToDate : "";
  const prevStatus = stateData && stateData.prevStatus ? stateData.prevStatus : "";
  const prevTipsType = stateData && stateData.prevTipsType ? stateData.prevTipsType : "";
  const prevTipsFromDate = stateData && stateData.prevTipsFromDate ? stateData.prevTipsFromDate : "";
  const prevTipsToDate = stateData && stateData.prevTipsToDate ? stateData.prevTipsToDate : "";
  const prevSearch = stateData && stateData.prevSearch ? stateData.prevSearch : "";
  const prevSort = stateData && stateData.prevSort ? stateData.prevSort : "";
  const prevPage = stateData && stateData.prevPage ? stateData.prevPage : 1;
  const prevNumRowsPerPage = stateData && stateData.prevNumRowsPerPage ? stateData.prevNumRowsPerPage : PAGINATION_NUM_ROWS;

  const {
    staffDetails,
    loading,
    QRCode,
    profilePic,
    documentImage,
    withholdingsDetails,
    countryStates
  } = useAppSelector(selectAccountStaffDataSlice);

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const [confirmDeleteDocImg, setConfirmDeleteDocImg] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [pools, setPools] = useState([]);
  const [avatar, setAvatar] = useState(avatarImg);
  const [qrCodeImg, setQrCodeImg] = useState("");
  const [qrCodeLink, setQrCodeLink] = useState("");
  const [qrCodePdf, setQrCodePdf] = useState("");
  const [qrcScanCountTotal, setQRCScanCountTotal] = useState("0");
  const [qrcScanCount30Days, setQRCScanCount30Days] = useState("0");
  const [isActive, setIsActive] = useState(true);

  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [zipError, setZipError] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [countryError, setCountryError] = useState(false);

  const [staffID, setStaffID] = useState<number>(Number(params.staffId));
  const [selectedAccount, setSelectedAccount] = useState<number>(
    Number(params.accountId)
  );
  const [staffAccount, setStaffAccount] = useState([
    {
      hotelId: 0,
      hotelName: "",
    },
  ]);

  const [editPaymentSetting, setEditPaymentSetting] = useState(false);
  const [paymentDetailsPrev, setPaymentDetailsPrev] = useState({
    bankName: "",
    accountHolderName: "",
    accountName: "",
    accountNumber: "",
    routingNumber: "",
    payoutSettings: 0,
    payoutFrequency: 0,
    dateOfBirth: "",
  });
  const [paymentDetails, setPaymentDetails] = useState({
    bankName: "",
    accountHolderName: "",
    accountName: "",
    accountNumber: "",
    routingNumber: "",
    payoutSettings: 3,
    payoutFrequency: 2,
    dateOfBirth: "",
  });

  const [editPersonalInfo, setEditPersonalInfo] = useState(false);
  const [personalInfo, setPersonalInfo] = useState({
    ssn: "",
    documentTypeId: "",
    documentIdNumber: "",
    documentExpiredDate: "",
    w9LastUpdate: "",
    idVrifiedByStripe: "yes",
  });
  const [personalInfoPrev, setPersonalInfoPrev] = useState({
    ssn: "",
    documentTypeId: "",
    documentIdNumber: "",
    documentExpiredDate: "",
    w9LastUpdate: "",
    idVrifiedByStripe: "yes",
  });

  const [editContactInfo, setEditContactInfo] = useState(false);
  const [contactInfoPrev, setcontactInfoPrev] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    apartment: "",
    address: "",
    city: "",
    state: "",
    country: "",
    zip: "",
  });
  const [contactInfo, setcontactInfo] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    apartment: "",
    address: "",
    city: "",
    state: "",
    country: "",
    zip: "",
  });

  const defStateList : IState[] = US_STATES_ARRAY; 
  const defCountryList : ICountry[]= [{ countryCode: t("defaultCountry"), countryName: t("defaultCountryName") } ]; 
  const defAllCountryStates : ICountryStates[]= [{ countryCode: t("defaultCountry"), countryName: t("defaultCountryName"), states: US_STATES_ARRAY } ]; 

  const [allCountryStatesList, setAllCountryStatesList] = useState <ICountryStates[]> (defAllCountryStates)
  const [countryList, setCountryList] = useState <ICountry[]> (defCountryList);
  const [stateList, setStateList] = useState <IState[]> (defStateList);

  const [phoneCountries, setPhoneCountries] = useState ([t("defaultCountry")]);

  const [dataLoaded, setDataLoaded] = useState (false);


  // COMMON FUNCTION TO GET STATE NAME FROM ITS CODE 
  const getStateName = (stateCode:string) => {
    if(!stateCode || stateCode.trim().length==0) {
      return "";
    }
    else {
      let tmpArr = stateList.find(item => item.stateCode==stateCode); 
      if(tmpArr) {
        return tmpArr.stateName; 
      }
      else {
        return stateCode; 
      }
    }
  }

  const getCountry = (countryCode:string) => {
    if(!countryCode || countryCode.trim().length==0) {
      return "";
    }
    else {
      let tmpArr = countryList.find(item => item.countryCode==countryCode); 
      if(tmpArr) {
        return tmpArr.countryName; 
      }
      else {
        return countryCode; 
      }
    }
  }

  const changeCountry = (countryCode:string) => {
    if(!countryCode || countryCode.trim().length==0) {
      setStateList([]); 
      setcontactInfo({
        ...contactInfo,
        state: "",
        country: "",
      }); 
    }
    else {
      allCountryStatesList.forEach(item => {
        if(item.countryCode===countryCode) {
          setStateList(item.states);  
        }
      }); 

      setcontactInfo({
        ...contactInfo,
        state: "",
        country: countryCode,
      });
      setCountryError(false); 
    }
  }

  const getStaffDocName = (docId: string) => {
    let objStaffDoc = ARR_STAFF_DOC_TYPES.filter(
      (data) => data.docId.toString() == docId
    );
    if (objStaffDoc && objStaffDoc.length) {
      return objStaffDoc[0].docName;
    } else {
      return "";
    }
  };

  useEffect(() => {
    // FETCH ALL COUNTRY AND STATES LIST 
    let dataObj : ICountryCode = { countryCode : "" }
    dispatch(fetchCountryStates(dataObj)); 
    fetchAll(); 
  }, [dispatch]);

  useEffect(() => {
    if(!countryStates || countryStates.length===0) {
      setAllCountryStatesList(defAllCountryStates);
      setCountryList(defCountryList); 
      setStateList(defStateList); 
      setPhoneCountries([t("defaultCountry")]); 
    }
    else {
      let arrCountries: ICountry[] = []; 
      let phCountires = [];   
      countryStates.forEach(item => {
        phCountires.push(item.countryCode.toLowerCase());
        arrCountries.push({ countryCode: item.countryCode, countryName: item.countryName}); 
        if(item.countryCode===t("defaultCountry")) {
          setStateList(item.states);  
        }
      }); 
      setCountryList(arrCountries); 
      setAllCountryStatesList(countryStates);
      setPhoneCountries(phCountires); 
    }
  }, [countryStates]);

  React.useEffect(() => {
    if(dataLoaded) {
      setEditContactInfo(true);
      setTimeout(() => {
        setEditContactInfo(false);
      }, 250);
    }
  },[dataLoaded]); 

  React.useEffect(() => {
    if (loading === "succeeded") {
      setPools(staffDetails?.pools ? staffDetails?.pools : []);
      setAvatar(
        staffDetails?.profilePic ? staffDetails?.profilePic : avatarImg
      );
      setQrCodeImg(staffDetails?.qrCodePng ? staffDetails.qrCodePng : "");
      setQrCodeLink(staffDetails?.qrCodeLink ? staffDetails.qrCodeLink : "");
      setQrCodePdf(staffDetails?.qrCodePdf ? staffDetails.qrCodePdf : "");

      setQRCScanCountTotal(staffDetails?.qrScanTotal ? staffDetails.qrScanTotal : "0");
      setQRCScanCount30Days(staffDetails?.qrScan_30Days ? staffDetails.qrScan_30Days : "0");

      setStaffAccount(staffDetails?.staffAccounts ? staffDetails?.staffAccounts : []);
      setIsActive(staffDetails?.active ? staffDetails?.active : false);
      // staffDetails.accountNumber?.length > 4
      //       ? staffDetails.accountNumber?.slice(-4)
      //         : staffDetails.accountNumber
      setPaymentDetails({
        bankName: staffDetails.bankName ? staffDetails.bankName : "",
        accountHolderName: staffDetails.accountHolderName
          ? staffDetails.accountHolderName
          : "",
        accountName: staffDetails.accountName ? staffDetails.accountName : "",
        accountNumber: staffDetails.accountNumber
          ? staffDetails.accountNumber
          : "",
        routingNumber: staffDetails.routingNumber
          ? staffDetails.routingNumber
          : "",
        payoutSettings: staffDetails?.payout ? staffDetails?.payout : 1,
        payoutFrequency: staffDetails?.payoutFrequency
          ? staffDetails?.payoutFrequency
          : 1,
        dateOfBirth: staffDetails?.dateOfBirth ? staffDetails?.dateOfBirth : "",
      });

      setPersonalInfo({
        ssn: staffDetails.ssn ? staffDetails.ssn : "",
        documentTypeId: staffDetails.documentTypeId
          ? staffDetails.documentTypeId
          : "",
        documentIdNumber: staffDetails.documentIdNumber
          ? staffDetails.documentIdNumber
          : "",
        documentExpiredDate: staffDetails.documentExpiredDate
          ? staffDetails.documentExpiredDate
          : "",
        w9LastUpdate: staffDetails.w9LastUpdate
          ? dayjs(staffDetails.w9LastUpdate).format("MM/DD/YYYY hh:mm A")
          : "",
        idVrifiedByStripe: staffDetails.documentVerified ? "yes" : "no",
      });

      setcontactInfo({
        firstName: staffDetails.firstName ? staffDetails.firstName : "",
        lastName: staffDetails.lastName ? staffDetails.lastName : "",
        phone: staffDetails.mobileNumber ? staffDetails.mobileNumber : "",
        email: staffDetails.email ? staffDetails.email : "",
        apartment: staffDetails.apartment ? staffDetails.apartment : "",
        address: staffDetails.address ? staffDetails.address : "",
        city: staffDetails.city ? staffDetails.city : "",
        state: staffDetails.state ? staffDetails.state : "",
        country: staffDetails.country ? staffDetails.country : "",
        zip: staffDetails.zip ? staffDetails.zip : "",
      });

      allCountryStatesList.forEach(item => {
        if(item.countryCode===staffDetails.country) {
          setStateList(item.states);  
        }
      }); 

      setDataLoaded(true); 

    }
    if (loading === "QRfetched") {
      setQrCodeImg(QRCode.qrCodePng ? QRCode.qrCodePng : "");
      setQrCodeLink(QRCode?.qrCodeLink ? QRCode.qrCodeLink : "");
      setQrCodePdf(QRCode?.qrCodePdf ? QRCode.qrCodePdf : "");
      setQRCScanCountTotal(QRCode?.qrScanTotal ? QRCode.qrScanTotal : "0");
      setQRCScanCount30Days(QRCode?.qrScan_30Days ? QRCode.qrScan_30Days : "0");
      setIsActive(QRCode.active ? QRCode.active : true);
      setPaymentDetails({
        ...paymentDetails,
        payoutSettings: QRCode.payout ? QRCode.payout : 3,
        payoutFrequency: QRCode.payoutFrequency ? QRCode.payoutFrequency : 2,
      });
      setPools(QRCode.pools ? QRCode.pools : []);
    }
    if (loading === "uploaded") {
      setAvatar(profilePic ? profilePic : avatarImg);
    }
    if (loading === "updated") {
      if (editPersonalInfo) {
        setEditPersonalInfo(false);
      }
      if (editContactInfo) {
        setEditContactInfo(false);
      }
    }
  }, [loading]);

  const fetchAll = () => {
    const req: IStaffDetails = {
      staffId: staffID,
      accountId: selectedAccount,
    };
    dispatch(fetchStaff(req));
    setLastActivePageAPIData({ pageName: pageName, apiData: req });
  };

  const editButton = (name): any => {
    const handleEdit = (name) => {
      if (name === "editPaymentSetting") {
        setPaymentDetailsPrev({
          bankName: paymentDetails.bankName,
          accountHolderName: paymentDetails.accountHolderName,
          accountName: paymentDetails.accountName,
          accountNumber: paymentDetails.accountNumber,
          routingNumber: paymentDetails.routingNumber,
          payoutSettings: paymentDetails.payoutSettings,
          payoutFrequency: paymentDetails.payoutFrequency,
          dateOfBirth: paymentDetails.dateOfBirth,
        });
        setEditPaymentSetting(true);
      } else if (name === "editPersonalInfo") {
        setPersonalInfoPrev({
          ssn: personalInfo.ssn,
          documentTypeId: personalInfo.documentTypeId,
          documentIdNumber: personalInfo.documentIdNumber,
          documentExpiredDate: personalInfo.documentExpiredDate,
          w9LastUpdate: personalInfo.w9LastUpdate,
          idVrifiedByStripe: personalInfo.idVrifiedByStripe,
        });
        setEditPersonalInfo(true);
      } else if (name === "editContactInfo") {
        setcontactInfoPrev({
          firstName: contactInfo.firstName,
          lastName: contactInfo.lastName,
          phone: contactInfo.phone,
          email: contactInfo.email,
          apartment: contactInfo.apartment,
          address: contactInfo.address,
          city: contactInfo.city,
          state: contactInfo.state,
          country: contactInfo.country,
          zip: contactInfo.zip,
        });
        setEditContactInfo(true);
      }
    };
    return (
      <>
        {checkActionPermission(adminUserRole, PERM_STAFF_EDIT) && <ButtonInLine
          type="edit_or_save"
          onClick={(e) => handleEdit(name)}
          buttonLabel={t("common.edit")}
          penIcon={true}
        />}
      </>
    );
  };

  const saveButton = (name): any => {
    const saveEdit = (name) => {
      if (name === "editPaymentSetting") {
        if (paymentDetails.bankName.length !== 0 &&
          paymentDetails.bankName !== "" &&
          paymentDetails.routingNumber.length !== 0 &&
          paymentDetails.routingNumber !== "" &&
          paymentDetails.accountHolderName.length !== 0 &&
          paymentDetails.accountHolderName !== "" &&
          paymentDetails.accountNumber.length !== 0 &&
          paymentDetails.accountNumber !== "" ||
          (paymentDetails.bankName.length == 0 &&
            paymentDetails.bankName == "" &&
            paymentDetails.routingNumber.length == 0 &&
            paymentDetails.routingNumber == "" &&
            paymentDetails.accountHolderName.length == 0 &&
            paymentDetails.accountHolderName == "" &&
            paymentDetails.accountNumber.length == 0 &&
            paymentDetails.accountNumber == ""
          )) {
          var details: IStaffDetails = {
            staffId: staffID,
            bankName: paymentDetails.bankName,
            routingNumber: paymentDetails.routingNumber,
            accountHolderName: paymentDetails.accountHolderName,
            accountNumber: paymentDetails.accountNumber,
            currency: `${t("defaultCurrency")}`,
            country: `${t("defaultCountry")}`,
            dateOfBirth: paymentDetails.dateOfBirth,
          };
          dispatch(updateStaffDetail(details));
          setEditPaymentSetting(false);
        } else {
          setNotification(
            t("messages.appName"),
            t("messages.paymentAccountValidation"),
            "failed",
            "updateBankAccountDetail"
          );
        }
      } else if (name === "editPersonalInfo") {
        // SELECT OPTION NOT AVAILABLE IN ID DOCUENT TYPE SELECT BOX
        // SO CHECK WHETHER IT IS SELECTED< IF NOT ASSIGN THE FIRST VALUE TO IT
        var details: IStaffDetails = {
          staffId: staffID,
          ssn: personalInfo.ssn,
          documentTypeId: Number(personalInfo.documentTypeId)
            ? Number(personalInfo.documentTypeId)
            : ARR_STAFF_DOC_TYPES[0].docId,
          documentIdNumber: personalInfo.documentIdNumber,
          documentExpiredDate: personalInfo.documentExpiredDate,
        };
        if (
          !personalInfo.documentTypeId ||
          !Number(personalInfo.documentTypeId)
        ) {
          setPersonalInfo({
            ...personalInfo,
            documentTypeId: ARR_STAFF_DOC_TYPES[0].docId.toString(),
          });
        }
        dispatch(updateStaffDetail(details));
      } else if (name === "editContactInfo") {
        if (
          contactInfo.firstName.trim().length > 0 &&
          contactInfo.lastName.trim().length > 0 &&
          contactInfo.phone.trim().length > 0 &&
          contactInfo.email.trim().length > 0 &&
          contactInfo.zip.trim().length > 0 && 
          contactInfo.state.trim().length > 0 && 
          contactInfo.country.trim().length > 0 && 
          isEmail(contactInfo.email.trim()).length == 0
        ) {
          var details: IStaffDetails = {
            staffId: staffID,
            firstName: contactInfo.firstName,
            lastName: contactInfo.lastName,
            mobileNumber: contactInfo.phone,
            email: contactInfo.email,
            apartment: contactInfo.apartment,
            address: contactInfo.address,
            city: contactInfo.city,
            state: contactInfo.state,
            country: contactInfo.country,
            zip: contactInfo.zip,
          };
          dispatch(updateStaffDetail(details));
        } else {
          if (contactInfo.firstName.trim().length == 0) {
            setFirstNameError(true);
          }
          if (contactInfo.lastName.trim().length == 0) {
            setLastNameError(true);
          }
          if (contactInfo.phone.trim().length == 0) {
            setPhoneError(true);
          }
          if (
            contactInfo.email.trim().length == 0 ||
            isEmail(contactInfo.email.trim()).length > 0
          ) {
            setEmailError(true);
          }
          if (contactInfo.zip.trim().length == 0) {
            setZipError(true);
          }
          if (contactInfo.state.trim().length == 0) {
            setStateError(true);
          }
          if (contactInfo.country.trim().length == 0) {
            setCountryError(true);
          }
        }
      }
    };

    const cancelEdit = (name) => {
      if (name === "editPaymentSetting") {
        setPaymentDetails({
          bankName: paymentDetailsPrev.bankName,
          accountHolderName: paymentDetailsPrev.accountHolderName,
          accountName: paymentDetailsPrev.accountName,
          accountNumber: paymentDetailsPrev.accountNumber,
          routingNumber: paymentDetailsPrev.routingNumber,
          payoutSettings: paymentDetailsPrev.payoutSettings,
          payoutFrequency: paymentDetailsPrev.payoutFrequency,
          dateOfBirth: paymentDetailsPrev.dateOfBirth,
        });
        setEditPaymentSetting(false);
      } else if (name === "editPersonalInfo") {
        setPersonalInfo({
          ssn: personalInfoPrev.ssn,
          documentTypeId: personalInfoPrev.documentTypeId,
          documentIdNumber: personalInfoPrev.documentIdNumber,
          documentExpiredDate: personalInfoPrev.documentExpiredDate,
          w9LastUpdate: personalInfoPrev.w9LastUpdate,
          idVrifiedByStripe: personalInfoPrev.idVrifiedByStripe,
        });
        setEditPersonalInfo(false);
      } else if (name === "editContactInfo") {
        setcontactInfo({
          firstName: contactInfoPrev.firstName,
          lastName: contactInfoPrev.lastName,
          phone: contactInfoPrev.phone,
          email: contactInfoPrev.email,
          apartment: contactInfoPrev.apartment,
          address: contactInfoPrev.address,
          city: contactInfoPrev.city,
          state: contactInfoPrev.state,
          country: contactInfoPrev.country,
          zip: contactInfoPrev.zip,
        });
        setEditContactInfo(false);
      }
    };

    return (
      <div className="flex gap-4">
        <ButtonInLine
          type="edit_or_save"
          onClick={(e) => cancelEdit(name)}
          buttonLabel={t("common.cancel")}
        />
        <ButtonInLine
          type="edit_or_save"
          onClick={(e) => saveEdit(name)}
          buttonLabel={t("defaultSettings.save")}
        />
      </div>
    );
  };

  const onSelectAccount = (e) => {
    setSelectedAccount(e.target.value);
    const req: IStaffDetails = {
      staffId: staffID,
      accountId: e.target.value,
    };
    dispatch(getQrCode(req));
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(qrCodeLink);

    setNotification(
      t("projectName"),
      t("common.linkCopied"),
      "success",
      "linkCopiedStaffDetail"
    );
  };

  const onSetAvatar = (e) => {
    const file = e.target.files[0];

    if (!file) {
      return; // If no file is selected, exit the function
    }

    const fileName = file.name;
    if (!isValidFileExtension(fileName)) {
      // Invalid file extension, handle the error or show a message to the user
      setNotification(
        t("projectName"),
        t("messages.logoUploadFailed"),
        "failed",
        "uploadAccountLogo"
      );
      return; // Exit the function if the file extension is invalid
    }

    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setAvatar(reader.result);
        const req: IStaffDetails = {
          staffId: staffID,
          profilePic: reader.result,
        };
        dispatch(uploadProfilePic(req));
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const onRemoveAvatar = () => {
    setAvatar(avatarImg);
    const req: IStaffDetails = {
      staffId: staffID,
      // profilePic: avatar,
    };
    dispatch(removeLogo(req));
  };

  const setVerificationImage = (e: any): void => {
    const file = e.target.files[0];

    if (!file) {
      return; // If no file is selected, exit the function
    }

    const fileName = file.name;
    if (!isValidFileExtension(fileName)) {
      // Invalid file extension, handle the error or show a message to the user
      setNotification(
        t("projectName"),
        t("messages.logoUploadFailed"),
        "failed",
        "uploadAccountLogo"
      );
      return; // Exit the function if the file extension is invalid
    }

    const reader = new FileReader();

    const req: IStaffDetails = {
      staffId: staffID,
      documentTypeId: 3,
      verificationDocument: e.target.files[0],
    };


    dispatch(uploadVerificationDocument(req));

    reader.readAsDataURL(e.target.files[0]);
  };

  const deleteVerificationImage = () => {
    const req: IStaffDetails = {
      staffId: staffID,
    };
    dispatch(deleteVerificationImg(req));
    setConfirmDeleteDocImg(false);
  };

  const returntoStaffList = () => {
    let resetStaffDet: IStaffDetails = {
      accountName: "",
      payout: 0,
      payoutFrequency: 0,
      pools: [],
      staffAccounts: [],
      staffId: 0,
      profilePic: "",
      firstName: "",
      lastName: "",
      mobileNumber: "",
      email: "",
      apartment: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      country: "",
      currency: "",
      alternativeMobileNumber: "",
      addressLine1: "",
      addressLine2: "",
      qrCodePng: "",
      qrCodeLink: "",
      qrCodePdf: "",
      ssn: "",
      signature: "",
      isW9Generated: false,
      w9LastUpdate: "",
      paymentMethod: 0,
      documentVerified: false,
      documentTypeId: 0,
      documentIdNumber: "",
      documentExpiredDate: "",
      accountHolderName: "",
      accountNumber: "",
      bankName: "",
      routingNumber: "",
      dateOfBirth: "",
      active: false,
      w9Url: "",
    };

    dispatch(setAccountStaff(resetStaffDet));

    navigate(ROUTE_STAFF_LIST, {
      state: {
        calledFrom: 'staffDetailsPage',
        prevAccIds: prevAccIds,
        prevDateFilterType: prevDateFilterType,
        prevFromDate: prevFromDate,
        prevToDate: prevToDate,
        prevStatus: prevStatus,
        prevTipsType: prevTipsType,
        prevTipsFromDate: prevTipsFromDate,
        prevTipsToDate: prevTipsToDate,
        prevSearch: prevSearch,
        prevSort: prevSort,
        prevPage: prevPage,
        prevNumRowsPerPage: prevNumRowsPerPage,
      }
    });
  };

  const setDateFormatValue = (dateValue: string, callFrom: string) => {
    let arrTmp = dateValue.split("-");
    let yyyy = arrTmp.length > 1 && arrTmp[0] ? arrTmp[0].substring(0, 4) : "00";
    let mm = arrTmp.length > 1 && arrTmp[1] ? arrTmp[1] : "00";
    let dd = arrTmp.length > 1 && arrTmp[2] ? arrTmp[2] : "00";
    let dateString = (Number(yyyy) && Number(mm) && Number(dd)) ? (yyyy + "-" + mm + "-" + dd) : "";
    if (callFrom == "personalInfo") {
      setPersonalInfo({ ...personalInfo, documentExpiredDate: dateString });
    } else if (callFrom == "paymentDetails") {
      setPaymentDetails({ ...paymentDetails, dateOfBirth: dateString });
    }
  };

  const withholdingSelect = (): any => {
    const options: any = [];
    const currentYear = new Date().getFullYear();
    for (let year = WITHHOLDING_YEAR; year <= currentYear; year++) {
      options.push(
        <option key={year} value={year}>
          {year}
        </option>
      );
    }

    const onSetSelectedYear = (value) => {
      setSelectedYear(value)
      const req: IStaffDetails = {
        staffId: staffID,
        year: value,
      };
      dispatch(fetchWithholdingsDetail(req));
    }
    return (
      <SelectField
        value={selectedYear}
        onChange={(e) => onSetSelectedYear(e.target.value)}
        options={options}
      />
    );
  };

  const getStatusBgColor = (statusString: number) => {
    switch (statusString) {
      case PAY_STATUS_CODE_SUCCESS: return ' color-success-status-bg ';
      case PAY_STATUS_CODE_READY: return ' color-ready-status-bg ';
      case PAY_STATUS_CODE_ONHOLD: return ' color-ready-status-bg ';
      case PAY_STATUS_CODE_PENDING: return ' color-pending-status-bg ';
      case PAY_STATUS_CODE_FAILED: return ' color-danger-status-bg ';
      default: return '';
    }
  }

  const getStatusBgColorDiv = (statusString: number) => {
    switch (statusString) {
      case PAY_STATUS_CODE_SUCCESS: return ' bg-success-payment ';
      case PAY_STATUS_CODE_READY: return ' bg-ready-payment ';
      case PAY_STATUS_CODE_ONHOLD: return ' bg-ready-payment ';
      case PAY_STATUS_CODE_PENDING: return ' bg-pending-payment ';
      case PAY_STATUS_CODE_FAILED: return ' bg-danger-payment ';
      default: return '';
    }
  }

  const handleCopyValue = (value: string) => {
    navigator.clipboard.writeText(value)
      .then(() => {
        setNotification(
          t("messages.appName"),
          t("messages.cpyMessage", { value: value }),
          "success",
          "uploadAccountLogo"
        );
      })
      .catch((error) => {
        console.error('Error copying value:', error);
      });
  };

  const dummyFunction = () => {
    return ""; 
  }

  return (
    <div className="box-padding">
      <div className="box">
        <div className="account">
          <div className="text-left mb-2">
            <div className="returnAcc pointer" onClick={(e) => returntoStaffList()} >
              <img src={back} alt="back" height={"16px"} className="me-1" />{" "}
              {t("staffprofile.returntostaff")}
            </div>
          </div>
          <div className=" space-between mb-4">
            <h1>
              {contactInfo.firstName}&nbsp;{contactInfo.lastName}
            </h1>
            <div className="flex gap-4">
              <ButtonPrimary
                to={ROUTE_ACCOUNT_DETAILS + "/" + selectedAccount}
                link={true}
                buttonLabel={t("staffprofile.viewAccount")}
              />
              <ButtonPrimary
                to={ROUTE_TIPS_LIST}
                link={true}
                buttonLabel={t("staffprofile.viewTips")}
              />
              <ButtonPrimary
                to={ROUTE_PAYMENTS_LIST}
                link={true}
                buttonLabel={t("staffprofile.viewPayouts")}
              />
              {/* QR CODES ORDERING - ONLY ALLOWED FOR SUPERADMIN AND ACCOUNT MANAGER  */}
              {checkActionPermission(adminUserRole, PERM_QRCODE_ORDER) && (<ButtonPrimary
                to={ROUTE_QRCODES} 
                link={true}
                buttonLabel={t("staffprofile.orderQrCards")} 
              />)}

            </div>
          </div>

          <div className="h-two mt-3">{t("staffprofile.staffInfo")}</div>
          <div className="flex gap-25px my-2">
            <div className="w-100 max-width-359px">
              <div className="avatar-box w-100">
                <img src={avatar} alt="logo" className="logoImg" />
                <div className="w-100 flex-col flex-end gap-3">
                  <div>&nbsp;</div>

                  {checkActionPermission(adminUserRole, PERM_STAFF_EDIT) && <>
                    <ButtonInLine
                      type="label"
                      htmlFor="upload"
                      uploadIcon={true}
                      buttonLabel={t("staffprofile.uploadAvatarBtn")}
                      inputName="upload"
                      inputId="upload"
                      onChange={(e) => onSetAvatar(e)}
                      className="pointer"
                    />

                    {avatar && avatar.trim().length > 3 && avatar != avatarImg && (
                      <ButtonInLine
                        onClick={(e) => onRemoveAvatar()}
                        buttonLabel={t("staffprofile.removeAvatarBtn")}
                      />
                    )}
                  </>}
                </div>
              </div>
              <div className=" validFont text-end">{t("validImg")}</div>
            </div>


            <div className="avatar-box w-100 gap-3">
              <img
                src={qrCodeImg}
                style={{ height: "128px", width: "128px" }}
                alt={`${t("staffprofile.QrCode")}`}
              />
              <div className="flex-col space-between w-100">
                <div className="h-three profile-status-text">
                  {t("staffprofile.statusQR")}
                  {isActive ? (
                    <span className="text-success ps-2 text-uppercase">
                      {t("common.active")}
                    </span>
                  ) : (
                    <span className="text-danger ps-2 text-uppercase">
                      {t("common.inActive")}
                    </span>
                  )}
                </div>
                <div className="flex-col gap-1">

                  <div className="mt-2px">
                    <span className="h-three">{t("staffprofile.QRCodeScanTotal")} </span>
                    <span className="text-data relative"> {qrcScanCountTotal}</span>
                  </div>

                  <div className="margin-topM5">
                    <span className="h-three">{t("staffprofile.QRCodeScan30Days")} </span>
                    <span className="text-data relative"> {qrcScanCount30Days}</span>
                  </div>
                    
                  <div className="">
                    <ButtonInLine
                      type="link"
                      href={qrCodePdf}
                      download={true}
                      buttonLabel={t("staffprofile.DownloadQrcode")}
                    />
                  </div>  
                  <div className="mt-5px">
                    <ButtonInLine
                      onClick={handleCopy}
                      copyIcon={true}
                      buttonLabel={t("staffprofile.copyQrCodeLink")}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="avatar-box flex-col gap-2 w-100">
              <div className="w-100 flex">
                <div className="h-three col-5 pt-1 ">
                  {t("staffprofile.Accounts")}
                </div>
                <div className="">
                  <SelectField
                    className="rounded-7 max-width-165px "
                    value={selectedAccount}
                    onChange={(e) => onSelectAccount(e)}
                    options={
                      staffAccount &&
                      staffAccount.map((item, index) => (
                        <>
                          <option value={item.hotelId}>{item.hotelName}</option>
                        </>
                      ))
                    }
                  />
                </div>
              </div>
              <div className="w-100 flex">
                <div className="h-three col-5">{t("staffprofile.pools")}</div>
                <div className="multiselect w-100 max-height-100px">
                  <ul className="listStyle-none">
                    {pools.map((item) => (
                      <li>{item.poolName}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

          </div>

          {/* ************************ PAYMENT SETTINGS - START ************************ */}
          <div className="h-two  mt-3">
            {t("staffprofile.PaymentSettings")}{" "}
            {checkActionPermission(adminUserRole, PERM_STAFF_EDIT) && <>
              {editPaymentSetting ? (
                <div>{saveButton("editPaymentSetting")}</div>
              ) : (
                <div>{editButton("editPaymentSetting")}</div>
              )}
            </>}
          </div>

          <div className="editBox pb-30px">
            <div className="left-center my-1 h-23px">
              <div className="h-three col-2">{t("staffprofile.bankName")}</div>
              <div className="col-3">
                {!editPaymentSetting ? (

                  <span className="text-data" >{paymentDetails.bankName}</span>
                ) : (
                  <Textfield
                    type="text"
                    className={` ${!editPaymentSetting && "border-none bg-transparent"
                      }`}
                    disabled={!editPaymentSetting}
                    value={paymentDetails.bankName}
                    onChange={(e) =>
                      setPaymentDetails({
                        ...paymentDetails,
                        bankName: e.target.value,
                      })
                    }
                  />
                )}
              </div>
              <div className="col-1"></div>
              <div className="h-three col-2">
                {t("staffprofile.accountName")}
              </div>
              <div className="col-3">
                {!editPaymentSetting ? (

                  <span className="text-data" >
                    {paymentDetails.accountHolderName}
                  </span>
                ) : (
                  <Textfield
                    type="text"
                    className={`${!editPaymentSetting && "border-none bg-transparent"
                      }`}
                    disabled={!editPaymentSetting}
                    value={paymentDetails.accountHolderName}
                    onChange={(e) =>
                      setPaymentDetails({
                        ...paymentDetails,
                        accountHolderName: e.target.value,
                      })
                    }
                  />
                )}
              </div>
            </div>

            <div className={!editPaymentSetting ? "left-center my-1 mt-10px h-23px" : "left-center my-1  mt-20px h-23px"} >
              <div className="h-three col-2">
                {t("staffprofile.routingNumber")}
              </div>
              <div className="col-3">
                {!editPaymentSetting ? (

                  <span className="text-data" >
                    {paymentDetails.routingNumber}
                  </span>
                ) : (
                  <Textfield
                    type="text"
                    maxLength={9}
                    className={`  ${!editPaymentSetting && "border-none bg-transparent"
                      }`}
                    disabled={!editPaymentSetting}
                    value={paymentDetails.routingNumber}
                    onChange={(e) =>
                      setPaymentDetails({
                        ...paymentDetails,
                        routingNumber: e.target.value,
                      })
                    }
                  />
                )}
              </div>
              <div className="col-1"></div>
              <div className="h-three col-2">
                {t("staffprofile.accountNumber")}
              </div>
              <div className="col-3">
                {!editPaymentSetting ? (

                  <span className="text-data" >
                    {paymentDetails.accountNumber &&
                      paymentDetails.accountNumber.length >= 4
                      ? "*********" +
                      paymentDetails.accountNumber.substring(
                        paymentDetails.accountNumber.length - 4
                      )
                      : paymentDetails.accountNumber
                        ? `*********${paymentDetails.accountNumber}`
                        : ""}
                  </span>
                ) : (
                  <Textfield
                    type="text"
                    className={`${!editPaymentSetting && "border-none bg-transparent"
                      }`}
                    disabled={!editPaymentSetting}
                    value={paymentDetails.accountNumber}
                    onChange={(e) =>
                      setPaymentDetails({
                        ...paymentDetails,
                        accountNumber: e.target.value,
                      })
                    }
                  />
                )}
              </div>
            </div>

            <div className={!editPaymentSetting ? "left-center my-1 mt-10px h-23px" : "left-center my-1 mt-20px h-23px"} >
              <div className={!editPaymentSetting ? "h-three col-2 mt-3" : "h-three col-2 mt-3"}>{t("staffprofile.dob")}
                <div className="validFont mt-0">{t("common.dateFormat")}</div>
              </div>
              <div className="col-3">
                {!editPaymentSetting ? (
                  <span className="text-data">
                    {paymentDetails.dateOfBirth &&
                      paymentDetails.dateOfBirth.trim().length >= 10
                      ? dayjs(paymentDetails.dateOfBirth).format(`MM-DD-YYYY`)
                      : paymentDetails.dateOfBirth}
                  </span>
                ) : (
                  <><Textfield
                    type="date"
                    className={` ${!editPaymentSetting && "border-none bg-transparent"
                      }`}
                    disabled={!editPaymentSetting}
                    value={paymentDetails.dateOfBirth}
                    onChange={(e) =>
                      setDateFormatValue(e.target.value, "paymentDetails")
                    }
                  />
                  </>
                )}
              </div>
              <div className="col-1"></div>
              <div className="h-three col-2">&nbsp;</div>
              <div className="col-3 ">&nbsp;</div>
            </div>
          </div>

          {/* *************************** PERSONAL DETAILS - START *************************** */}
          <div className="h-two mt-3">
            {t("staffprofile.personalDetails")}{" "}
            {checkActionPermission(adminUserRole, PERM_STAFF_EDIT) && <>
              {editPersonalInfo ? (
                <div>{saveButton("editPersonalInfo")}</div>
              ) : (
                <div className={`${staffDetails.documentVerified ? "hide" : ""}`}>
                  {editButton("editPersonalInfo")}
                </div>
              )}
            </>}
          </div>

          <div className="editBox">
            <div className="left-center my-1 h-23px">
              {/* //SSN NUMBER IS HIDE FOR NOW, SO COMMENTED FOR FUTURE USE */}
              {/* <div className="h-three col-2">{t("staffprofile.SSN")}</div>
              <div className="col-3">
                {!editPersonalInfo ? (
                  <span className="text-data">
                    {personalInfo.ssn && personalInfo.ssn.length
                      ? `*****${personalInfo.ssn}`
                      : ""}
                  </span>
                ) : (
                  <Textfield
                    maxLength={4}
                    type="text"
                    className={` ${
                      !editPersonalInfo && "border-none bg-transparent"
                    }`}
                    disabled={!editPersonalInfo}
                    value={personalInfo.ssn}
                    onChange={(e) =>
                      setPersonalInfo({
                        ...personalInfo,
                        ssn: e.target.value,
                      })
                    }
                  />
                )}
              </div>
              */}

              <div className="h-three col-2">{t("staffprofile.typeOfID")}</div>
              <div className="col-3">
                {!editPersonalInfo ? (

                  <span className="text-data" >
                    {getStaffDocName(personalInfo.documentTypeId)}
                  </span>
                ) : (
                  <SelectField
                    type="inputType"
                    name=""
                    id=""
                    disabled={!editPersonalInfo}
                    value={personalInfo.documentTypeId}
                    onChange={(e) =>
                      setPersonalInfo({
                        ...personalInfo,
                        documentTypeId: e.target.value,
                      })
                    }
                    className={`${!editPersonalInfo
                      ? "border-none bg-transparent hide-selectarrow"
                      : "bg-white"
                      }`}
                    options={ARR_STAFF_DOC_TYPES.map((docData) => (
                      <>
                        <option value={docData.docId}>{docData.docName}</option>
                      </>
                    ))}
                  />
                )}
              </div>

              <div className="col-1"></div>

              <div className="h-three col-2">{t("staffprofile.IDNumber")}</div>
              <div className="col-3 ">
                {!editPersonalInfo ? (

                  <span className="text-data" >
                    {personalInfo.documentIdNumber}
                  </span>
                ) : (
                  <Textfield
                    type="text"
                    className={`${!editPersonalInfo && "border-none bg-transparent "
                      }`}
                    disabled={!editPersonalInfo}
                    value={personalInfo.documentIdNumber}
                    onChange={(e) =>
                      setPersonalInfo({
                        ...personalInfo,
                        documentIdNumber: e.target.value,
                      })
                    }
                  />
                )}
              </div>
            </div>

            <div className={!editPersonalInfo ? "left-center my-1 mt-10px h-23px" : "left-center my-1 mt-20px h-23px"}>
              <div className={!editPersonalInfo ? "h-three col-2 mt-4" : "h-three col-2 mt-2"} >
                {t("staffprofile.Expiration")}
                <div className="validFont mt-0">{t("common.dateFormat")}</div>
              </div>
              <div className="col-3">
                {!editPersonalInfo ? (

                  <span className="text-data" >
                    {personalInfo.documentExpiredDate &&
                      personalInfo.documentExpiredDate.trim().length >= 10
                      ? dayjs(personalInfo.documentExpiredDate).format(
                        `MM-DD-YYYY`
                      )
                      : personalInfo.documentExpiredDate}
                  </span>
                ) : (
                  <><Textfield
                    type="date"
                    className={` ${!editPersonalInfo && "border-none bg-transparent"
                      }`}
                    disabled={!editPersonalInfo}
                    value={personalInfo.documentExpiredDate}
                    onChange={(e) =>
                      setDateFormatValue(e.target.value, "personalInfo")
                    }
                  />
                  </>
                )}
              </div>

              <div className="col-1"></div>

              <div className="h-three col-2">
                {t("staffprofile.IDVerifiedByStripe")}
              </div>
              <div className="col-3">

                <span className="text-data p-6px" >
                  {personalInfo.idVrifiedByStripe == "yes"
                    ? t("common.yes")
                    : t("common.no")}
                </span>
                {/* <select
                  disabled={true}
                  value={personalInfo.idVrifiedByStripe}
                  className={`w-100 text-data rounded-2 p-6px  border-none bg-transparent hide-selectarrow`}
                >
                  <option value={"yes"}>{t("common.yes")}</option>
                  <option value={"no"}>{t("common.no")}</option>
                </select> */}
              </div>
            </div>

            <div className="left-center my-1  mt-10px h-23px">
              <div className="h-three col-2">
                {personalInfo.w9LastUpdate &&
                  personalInfo.w9LastUpdate.trim().length >= 10
                  ? t("staffprofile.lastUpdated")
                  : ""}
              </div>
              <div className="col-3">

                <span className="text-data" >
                  {personalInfo.w9LastUpdate &&
                    personalInfo.w9LastUpdate.trim().length >= 10
                    ? dayjs(personalInfo.w9LastUpdate).format(
                      `MM-DD-YYYY hh:mm A`
                    )
                    : personalInfo.w9LastUpdate}
                </span>
              </div>
              <div className="col-1"></div>
            </div>

            <div className="left-center my-1 pt-3 mt-10px">
              <div className="col-6">
                {staffDetails.isW9Generated && (
                  <ButtonInLine
                    type={"link"}
                    className="max-width-130px "
                    href={staffDetails.w9Url}
                    buttonLabel={t("staffprofile.downloadW9")}
                  />
                )}
              </div>
              <div className="h-three col-2">
                {
                  checkActionPermission(adminUserRole, PERM_STAFF_EDIT) && !staffDetails.documentVerified && documentImage.length < 4 && <>
                    <ButtonInLine
                      type="label"
                      htmlFor="uploadID"
                      className={`max-width-130px text-center  ${documentImage.length > 4 && "opacity-50"
                        }`}
                      uploadcloudIcon={true}
                      buttonLabel={t("staffprofile.uploadIDBtn")}
                      inputName="uploadID"
                      inputId="uploadID"
                      disabled={
                        documentImage.length > 4 || staffDetails.documentVerified
                          ? true
                          : false
                      }
                      onChange={(e: any) => setVerificationImage(e)}
                    />
                    <div className="max-width-336px validFont">{t("validImg")}</div>
                  </>
                }
              </div>
              <div className="col-3">
                {documentImage &&
                  documentImage.map((item: any, index) => (<>
                    <div className="relative " style={{ width: "60px" }}>
                      <Avatar src={item.image} variant="rounded" onClick={handleOpen} className="pointer" />
                      {!staffDetails.documentVerified && checkActionPermission(adminUserRole, PERM_STAFF_EDIT) && (
                        <IconButton
                          disabled={staffDetails.documentVerified}
                          sx={{
                            position: "absolute",
                            top: "-23px",
                            right: "-6px",
                          }}
                          onClick={() => setConfirmDeleteDocImg(true)}
                        >
                          <HighlightOffRoundedIcon sx={{ color: "red" }} />
                        </IconButton>
                      )}
                    </div>

                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style} className=" center flex-col">
                        <div className="w-100 relative">
                          <IconButton onClick={() => handleClose()} aria-label="delete" size="large" sx={{ position: "absolute", right: "-32px", top: "-32px" }}>
                            <CloseRoundedIcon sx={{ fontSize: "17px" }} />
                          </IconButton>
                        </div>
                        <img src={item.image} alt="" width={"auto"} height={"auto"} className="max-height-600px max-w-617px mt-1" />
                      </Box>
                    </Modal>
                  </>

                  ))}
              </div>
            </div>
          </div>
          {/* *************************** PERSONAL DETAILS - END *************************** */}

          {/* *************************** CONTACT INFORMATION - START *************************** */}
          <div className="h-two mt-3">
            {t("staffprofile.ContactInformation")}
            {checkActionPermission(adminUserRole, PERM_STAFF_EDIT) && <>
              {editContactInfo ? (
                <div>{saveButton("editContactInfo")}</div>
              ) : (
                <div>{editButton("editContactInfo")}</div>
              )}
            </>}
          </div>

          {/* Mandatory fields  firstName, lastName, phone, email, zip */}

          <div className="editBox">
            <div className="left-center h-23px my-1 pt-3 mt-20px">
              <div className="h-three col-2">{t("staffprofile.firstName")}</div>
              <div className="col-3">
                {!editContactInfo ? (

                  <span className="text-data" >
                    {contactInfo.firstName && contactInfo.firstName.length
                      ? contactInfo.firstName
                      : ""}
                  </span>
                ) : (
                  <Textfield
                    type="text"
                    className={firstNameError ? " input-error" : ""}
                    disabled={!editContactInfo}
                    value={contactInfo.firstName}
                    onChange={(e) => {
                      setcontactInfo({
                        ...contactInfo,
                        firstName: e.target.value,
                      });
                      setFirstNameError(false);
                    }}
                  />
                )}
              </div>

              <div className="col-1"></div>
              <div className="h-three col-2">{t("staffprofile.lastName")}</div>
              <div className="col-3">
                {!editContactInfo ? (

                  <span className="text-data" >
                    {contactInfo.lastName && contactInfo.lastName.length
                      ? contactInfo.lastName
                      : ""}
                  </span>
                ) : (
                  <Textfield
                    type="text"
                    className={lastNameError ? " input-error" : ""}
                    disabled={!editContactInfo}
                    value={contactInfo.lastName}
                    onChange={(e) => {
                      setcontactInfo({
                        ...contactInfo,
                        lastName: e.target.value,
                      });
                      setLastNameError(false);
                    }}
                  />
                )}
              </div>
            </div>

            <div className="left-center h-23px my-1 pt-3 mt-20px">
              <div className="h-three col-2">{t("staffprofile.phone")}</div>
              {/* onClick={(e) => handleCopyValue(contactInfo.phone) } */}
              {editContactInfo===true ? <>
                <div className={"col-3 hidesl"} >
                  <PhoneInput
                    inputProps={{
                      name: `mobile`,
                      required: true,
                      autoFocus: false,
                    }}
                    onlyCountries={(phoneCountries.length > 0 ? phoneCountries : PHONEINPUT_COUNTRIES)}
                    countryCodeEditable={true}
                    prefix={"+"}
                    disabled={false} 
                    inputClass={(phoneError ? " input-error common-input " : " common-input ")}
                    value={contactInfo.phone}
                    onChange={(e) => {
                      setcontactInfo({
                        ...contactInfo,
                        phone: e,
                      });
                      setPhoneError(false);
                    }}
                  />
                </div>
              </> : 
              <> <div className={"col-3 hidesl copy-cursor min-width-150px pr-60px"} style={{paddingTop: "10px", paddingBottom: "10px",}}>     
                <PhoneInput
                    prefix={"+"}
                    disabled={true} 
                    inputClass={"common-input pl-0 h-23px border-none bg-transparent min-width-110px"}
                    value={contactInfo.phone}
                  /> 
                </div>
                
                {/* <Tooltip
                  title={
                    <>
                      {t("common.copy")}
                      <ContentCopyIcon sx={{ fontSize: "12px" }} />
                    </>
                  }
                  placement="right"
                  arrow
                  disableInteractive
                >           </Tooltip> */}
              </>}

              <div className={!editContactInfo ? "col" : "col-1"}></div>
              <div className="h-three col-2">{t("staffprofile.email")}</div>
              <div className={!editContactInfo ? "col-4" : "col-3"}>
                {!editContactInfo ? (
                  <Tooltip
                    title={
                      <>
                        {t("common.copy")}
                        <ContentCopyIcon sx={{ fontSize: "12px" }} />
                      </>
                    }
                    placement="right"
                    arrow
                    disableInteractive
                  >
                    <span className="text-data copy-cursor" onClick={(e) => handleCopyValue(contactInfo.email && contactInfo.email.length
                      ? contactInfo.email
                      : "")}>
                      {contactInfo.email && contactInfo.email.length
                        ? contactInfo.email
                        : ""}
                    </span></Tooltip>
                ) : (
                  <Textfield
                    type="text"
                    className={emailError ? " input-error" : ""}
                    value={contactInfo.email}
                    onChange={(e) => {
                      setcontactInfo({
                        ...contactInfo,
                        email: e.target.value,
                      });
                      setEmailError(false);
                    }}
                  />
                )}
              </div>
            </div>

            <div className="left-center h-23px my-1 pt-3 mt-20px">
              <div className="h-three col-2">{t("staffprofile.apartment")}</div>
              <div className="col-3">
                {!editContactInfo ? (

                  <span className="text-data" >
                    {contactInfo.apartment && contactInfo.apartment.length
                      ? contactInfo.apartment
                      : ""}
                  </span>
                ) : (
                  <Textfield
                    type="text"
                    value={contactInfo.apartment}
                    onChange={(e) =>
                      setcontactInfo({
                        ...contactInfo,
                        apartment: e.target.value,
                      })
                    }
                  />
                )}
              </div>
              <div className="col-1"></div>
              <div className="h-three col-2">{t("staffprofile.address")}</div>
              <div className="col-3 hidesl">
                {!editContactInfo ? (

                  <span className="text-data">
                    {contactInfo.address && contactInfo.address.length
                      ? contactInfo.address
                      : ""}
                  </span>
                ) : (
                  <Textfield
                    type="text"
                    value={contactInfo.address}
                    onChange={(e) =>
                      setcontactInfo({
                        ...contactInfo,
                        address: e.target.value,
                      })
                    }
                  />
                )}
              </div>
            </div>

            <div className="left-center h-23px my-1 pt-3 mt-20px">
              <div className="h-three col-2">{t("staffprofile.city")}</div>
              <div className="col-3">
                {!editContactInfo ? (

                  <span className="text-data" >
                    {contactInfo.city && contactInfo.city.length
                      ? contactInfo.city
                      : ""}
                  </span>
                ) : (
                  <Textfield
                    type="text"
                    value={contactInfo.city}
                    onChange={(e) =>
                      setcontactInfo({
                        ...contactInfo,
                        city: e.target.value,
                      })
                    }
                  />
                )}
              </div>

              <div className="col-1"></div>
              <div className="h-three col-2">{t("staffprofile.state")}</div>
              <div className="col-3 hidesl">
                {!editContactInfo ? (
                  <span className="text-data" >
                    {contactInfo.state && contactInfo.state.length > 0 
                      ? getStateName(contactInfo.state)
                      : ""}
                  </span>
                ) : (
                  <SelectField
                    type="inputType"
                    name="txt_state"
                    id="txt_state"
                    className={stateError===true ? " select-placeholder input-error" : ""}
                    placeHolder={t("businessDetails.stateLabel")}
                    value={contactInfo.state}
                    onChange={(e) => {
                      setcontactInfo({
                        ...contactInfo,
                        state: e.target.value,
                      });
                      setStateError(false); 
                    }} 
                    required
                    options={stateList &&
                      stateList.map((item, index) => (
                        <>
                          {index == 0 && <>
                            <option value="" >{t("businessDetails.stateLabel")}</option>
                          </>}
                          <option value={item.stateCode} style={{ color: "#000000" }}>
                            {item.stateName}
                          </option>
                        </>
                      ))}
                  />
                )}
              </div>
            </div>

            <div className="left-center h-23px my-1 pt-3 mt-20px">
              <div className="h-three col-2">{t("staffprofile.zip")}</div>
              <div className="col-3">
                {!editContactInfo ? (

                  <span className="text-data" >
                    {contactInfo.zip && contactInfo.zip.length
                      ? contactInfo.zip
                      : ""}
                  </span>
                ) : (
                  <Textfield
                    type="text"
                    className={zipError ? " input-error" : ""}
                    value={contactInfo.zip}
                    onChange={(e) => {
                      setcontactInfo({
                        ...contactInfo,
                        zip: e.target.value,
                      });
                      setZipError(false);
                    }}
                  />
                )}
              </div>

              <div className="col-1"></div>
              <div className="h-three col-2">
                {t("staffprofile.country")}
              </div>
              <div className="col-3 hidesl">
              {!editContactInfo ? (
                  <span className="text-data" >
                    {contactInfo.country && contactInfo.country.length == 2
                      ? getCountry(contactInfo.country)
                      : ""}
                  </span>
                ) : (
                  <SelectField
                    type="inputType"
                    name="txt_country"
                    id="txt_country"
                    className={countryError===true ? " select-placeholder input-error " : ""}
                    placeHolder={t("staffprofile.country")}
                    value={contactInfo.country}
                    onChange={(e) => changeCountry(e.target.value)}
                    required
                    options={countryList &&
                      countryList.map((item, index) => (
                        <>
                          {index == 0 && <>
                            <option value="" >{t("staffprofile.country")}</option>
                          </>}
                          <option value={item.countryCode} style={{ color: "#000000" }}>
                            {item.countryName}
                          </option>
                        </>
                      ))}
                  />
                )}
              </div>
            </div>

          </div>
          {/* *************************** CONTACT INFORMATION - END *************************** */}

          {/* *************************** ANNUAL WITHHOLDINGS PAYOUT SETTINGS- START *************************** */}
          <div className="h-two mt-3">
            <div className="center gap-3">
              {t("staffprofile.withholdingsPayout")}

              {withholdingSelect()}
            </div>

            {/* {editContactInfo ? (
              <div>{saveButton("editContactInfo")}</div>
            ) : (
              <div>{editButton("editContactInfo")}</div>
            )} */}
          </div>

          <div className="editBox py-2">
            {(withholdingsDetails && withholdingsDetails.length > 0) ? (
              withholdingsDetails.map((item: any, index) => (
                <>
                  <div className="left-center my-2 ">
                    <div className="h-three col-2">
                      {t("staffprofile.ammountWithheld")}
                    </div>

                    <div className="col-3">

                      <span className="text-data" >
                        {t("common.dollar")} {item.amount}
                      </span>
                    </div>

                    <div className="col-1"></div>
                    <div className="h-three col-2">
                      {t("staffprofile.payoutOut")}
                    </div>
                    <div className="col-3 manage-modal">
                      <div className="detail-box px-0px pt-0px max-width-142px">
                        <div className={"flex status-payment " + getStatusBgColorDiv(item.paymentStatus)}>
                          <div className={"circle-10px ml-6px" + getStatusBgColor(item.paymentStatus)}></div>
                          <span>{GetPayoutStatusText(item.paymentStatus)}</span>
                        </div>

                        <div className="ml-6px">{item.transactionDate ? dayjs(item.transactionDate).format(`MM/DD/YYYY, hh:mm A`) : GetPayoutStatusText(item.paymentStatus)}</div>
                      </div>
                    </div>
                  </div>

                </>
              ))
            ) : (
              <>
                <div className="center my-2">
                  <div className="h-three col-2">
                    {t("common.noDataAvailable")}
                  </div>
                </div>
              </>
            )}
          </div>
          {/* *************************** ANNUAL WITHHOLDINGS PAYOUT SETTINGS- END *************************** */}
        </div>
        {/* )} */}
      </div>


      <Modal
        open={confirmDeleteDocImg}
        onClose={() => setConfirmDeleteDocImg(false)}
        aria-labelledby={"Remove ploaded ID"}
        aria-describedby=""
      >
        <Box sx={style1}>
          <div className="idproof-modal div-center text-center relative">
            <div className="page-heading">
              {/* <CloseRounded
                onClick={() => setStatusPopup(false)}
                className="close"
              /> */}
              <img src={warning} alt="" />
            </div>
            <div className="idproof-flex-center div-center">
              <div>
                {t("messages.removeUploadedID")}
              </div>
            </div>

            <div className="idproof-margin"></div>
            <div className="flex w-100">
              <ButtonModal
                type="cancel"
                onClick={(e) => setConfirmDeleteDocImg(false)}
                buttonLabel={t("common.cancel")}
              />
              <ButtonModal
                type="delete"
                onClick={(e) => deleteVerificationImage()}
                buttonLabel={t("uploadLogo.remove")}
                className=" ml-15px"
              />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default StaffDetail;
